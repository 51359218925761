import { IReportElement } from "@monorepo/reports/src/views/FundsListView/types/reportElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundsListReport/fields";

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

export const convertApiItemToUi = (item: IReportElement): Record<Values, string | number> => ({
  [fields.TABLE_NUMBER]: item.index || "",
  [fields.TABLE_FUND_NUMBER]: item.number || "",
  [fields.TABLE_DATE_RECEIVED]: item.dateFirstArrival || "",
  [fields.TABLE_FUND_NAME]: item.name || "",
});
