export interface IFundsListReport {
  FILTER_ARCHIVE: string;

  TABLE_NUMBER: string;
  TABLE_FUND_NUMBER: string;
  TABLE_DATE_RECEIVED: string;
  TABLE_FUND_NAME: string;
}

export const fields: IFundsListReport = {
  FILTER_ARCHIVE: "archiveName",

  TABLE_NUMBER: "index",
  TABLE_FUND_NUMBER: "number",
  TABLE_DATE_RECEIVED: "dateFirstArrival",
  TABLE_FUND_NAME: "name",
};
