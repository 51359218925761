import { v4 as uuid } from "uuid";
// import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { fields } from "./fields";

export const tableHeaders = [
  // headerCheckboxObject(),
  {
    text: "№ п/п",
    tooltip: "Номер по порядку",
    defaultWidth: 100,
    value: fields.TABLE_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Номер фонда",
    tooltip: "Номер фонда",
    defaultWidth: 300,
    value: fields.TABLE_FUND_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Дата первого поступления",
    tooltip: "Дата первого поступления в фонд",
    defaultWidth: 300,
    value: fields.TABLE_DATE_RECEIVED,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Название фонда",
    tooltip: "Полное название фонда",
    defaultWidth: 200,
    value: fields.TABLE_FUND_NAME,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];
